<template>
  <q-page class="q-pa-none q-py-md row justify-center">
    <q-card class="col-11">
      <q-card-section class="q-pa-none">
        <q-linear-progress class="absolute" indeterminate v-if="loading" />
        <q-toolbar>
          <q-space />
          <q-btn auto-close flat round icon="r_more_vert">
            <q-menu auto-close>
              <q-list>
                <q-item clickable @click="ReadData">
                  <q-item-section>Refresh</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </q-toolbar>
      </q-card-section>
      <q-card-section style="height: calc(100vh - 132px)">
        <q-list
          class="q-pa-sm"
          style="height: calc(100% - 60px); overflow-y: auto"
        >
          <q-item
            v-for="(m, i) in items"
            :key="i"
            class="q-card q-mb-sm"
            clickable
          >
            <q-item-section avatar>
              <ProfileAvatar listmode :text1="m[3]" class="q-mb-auto" />
            </q-item-section>
            <q-item-section>
              <q-item-label class="text-h6">{{ m[3] }}</q-item-label>
              <q-item-label>{{ m[2] }}</q-item-label>
              <q-item-label caption>{{ m[0] }}</q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-item-label class="text-h6">{{ m[1] }}</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>

        <q-tabs align="justify" v-model="tab" switch-indicator>
          <q-tab name="expense" icon="mail" label="Expense" />
          <q-tab name="income" icon="alarm" label="Income" />
        </q-tabs>
      </q-card-section>
    </q-card>
  </q-page>
</template>

<script>
import {
  defineComponent,
  defineAsyncComponent,
  ref,
  onMounted,
  computed,
  watch,
} from "vue";
//import api from "../api/index";
import { useStore } from "vuex";

export default defineComponent({
  name: "Summary",
  components: {
    ProfileAvatar: defineAsyncComponent(() =>
      import("../components/ProfileAvatar.vue")
    ),
  },
  setup() {
    const store = useStore();
    const SheetId = store.getters["SheetId"];
    const key = store.getters["Key"];
    const tab = ref("expense");
    const signedIn = computed(() => store.getters["SignedIn"]);
    const loading = ref(false);

    const columns = ref([
      { label: "Time", align: "left" },
      { label: "Category", align: "left" },
      { label: "Amount", align: "left" },
      { label: "Notes", align: "left" },
    ]);

    const items = ref([]);

    const ReadData = () => {
      if (signedIn.value) {
        loading.value = true;
        window.gapi.client.sheets.spreadsheets.values
          .get({
            spreadsheetId: SheetId,
            range: [
              tab.value === "expense"
                ? "Transactions!B5:E"
                : "Transactions!G5:J",
            ],
          })
          .then((response) => {
            loading.value = false;
            items.value = [...response.result.values];
          })
          .catch(() => {
            loading.value = false;
          });
      } else {
        items.value = [];
      }
    };

    watch([tab, signedIn], () => {
      ReadData();
    });

    onMounted(() => {
      store.dispatch("UpdateHeader", "Transactions");
      ReadData();
    });

    return {
      loading,
      ReadData,
      tab,
      items,
      columns,
    };
  },
});
</script>
